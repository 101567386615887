import { Card } from "antd";
import React from "react";
import { useDrop } from "react-dnd";


export default function PublisherCard({ id, onDrop, drop_type = "", className = "", dragging = false, setDragging = () => { }, children }) {

  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: drop_type,
    drop: (item) => onDrop(item, id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  let _cls = className; 

  if(isOver){
    _cls += " dropping";
  }

  return (
    <div ref={(node) => dropRef(node)}>

      <Card bodyStyle={{ "padding": "5px 10px" }} className={_cls}
      // style={{ backgroundColor: (isOver || dragging) ? "#e6f7ff" : "#fbfbfb" }}
      >
        {children}
      </Card>
    </div>
  );
}
