import { Card, Table } from "antd";
import React, { useEffect, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";



export default function PublisherRow(props) {
    const {
        id = "",
        item = {},
        type = "publisher",
        accept = "",
        onDrop = () => { },
        dragging = false,
        className = "",
        setDragging = () => { },
        can_drag = true, // New parameter
    } = props;

    const [{ isDragging }, dragRef] = useDrag(() => ({
        type: type,
        item: { id : id, item : item, type : type },
        canDrag: can_drag, // Use the can_drag parameter here
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [can_drag]); // Dependency to handle changes in can_drag

    const [{ isOver }, dropRef] = useDrop(() => ({
        accept: accept,
        drop: (item) => onDrop(item, id),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }));

    useEffect(() => {
        // Update dragging state if necessary
        if(isDragging){
            setDragging(type)
        } else {
            setDragging("")
        }
    }, [isDragging, setDragging]);

    return (
        <div
            className={className}
            ref={(node) => can_drag ? dragRef(dropRef(node)) : dropRef(node)} // Conditional application of dragRef
            style={{
                filter : (isOver) ? "hue-rotate(180deg)" : "hue-rotate(0deg)",
                padding: "0px 0px",
                cursor: can_drag ? "grab" : "pointer", // Adjust cursor based on can_drag
            }}
        >
            {/* {isOver.toString()}
            {accept} */}
            {props.children}
        </div>
    );
}
