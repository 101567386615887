import { MoreOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Checkbox, Col, Divider, Form, Input, Layout, message, Popover, Radio, Row, Select, Space, Spin, Typography, Upload } from "antd";
import React, { useEffect, useState } from "react";

import LayoutHeader from "../../layouts/partials/LayoutHeader";
import { apiCall } from "../../utils/Api";
import PublisherCard from './PublisherCard';

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PublisherRow from './PublisherRow';
import Overlay from '../../components/Overlay';
import { final_publishers, linked_publishers, transformFinal } from '../../misc';
import { pluralize } from '../../utils/Utils';
import Html from '../../utils/Html';


export default function MyPublishersPage() {



    const [selected, setSelected] = useState("");


    const getColor = (_type) => {

        switch (_type) {

            case "finished":
                return "#99ff99"
                break;
            case "pipm_publisher":
                return "#ffb866"
                break;
            case "pipm_id":
                return "#D9B982"
                break;
            default: // dirty
                return "#ffbbbb"
                break;

        }

    }







    // const fp = { ...final_publishers, "rows": transformFinal(final_publishers.rows) }



    // {
    //     "v": "Klutz",
    //     "k": "Klutz",
    //     "t": "pip_publisher",
    //     "s": [
    //         {
    //             "v": "Chicken Socks",
    //             "k": "131259",
    //             "t": "pip_id",
    //             "f": "moved finished",
    //             "c": "8",
    //             "cnt_titles": "8"
    //         },
    //         {
    //             "v": "Klutz",
    //             "k": "9119",
    //             "t": "pip_id",
    //             "f": "moved finished",
    //             "c": "283",
    //             "cnt_titles": "283"
    //         }
    //     ],
    //     "f": "moved finished",
    //     "c": 291,
    //     "cnt_imprints": 2,
    //     "cnt_titles": 291
    // }


    const [dragging, setDragging] = useState(false);

    const onDrop = (item, target) => {

        let {type = ""} = item; 

        if(type === "search"){
            // prefix selected
            if(target === "final"){
                // set final prefix text and filter
                setPrefix(item?.id || "");
                setFilters({...filters, "isbn_prefix_filter" : item?.id || ""});
            } else {
                // set all
                setPrefix2(item?.id || "");
                setAllFilters({...allFilters, "isbn_prefix_filter" : item?.id || ""});
            }
        }
       
        // alert(JSON.stringify(item))

    };


    const PubItem = (props) => {

        const { data = {}, callback = () => { }, className = "", can_drag = true,  type = "" } = props;

        const {
            label = "",
            id = "",
            accept = "",
            imprints = [],
            status = "",
            count = null,
            imprint_count = null,
            title_count = null
        } = data;

        const [show, setShow] = useState(false)

        return (
            <div className={className + " bc"} style={{ "borderBottom": "1px solid" }}>
                <div style={{ "padding": "4px 10px" }} className='float-flex'>
                    <div onClick={() => { callback(data) }} style={{ "width": "100%" }}>
                        <div className='shim' />
                        <div><small><Html className="pub-text" html={label} /></small></div>
                        <div className='float-flex'>
                            <div>&nbsp;</div>
                            <Space>
                                <a onClick={(e) => {
                                    e.stopPropagation();
                                    setShow(!show);
                                }}><small>{imprint_count} {pluralize(imprint_count, "imprint", "s")}</small></a>
                                <a onClick={(e) => {
                                    e.stopPropagation();
                                    setShow(!show);
                                }}
                                ><small>{title_count} {pluralize(title_count, "title", "s")}</small></a>
                            </Space>
                        </div>
                    </div>
                    <a>
                        <div style={{ "width": "20px", "padding": "5px" }}>
                            <Popover overlayStyle={{ "padding": "0px" }} placement="right" trigger="click" content={<div>
                                <Space size={0} direction="vertical">
                                    <Button className="mini-btn" style={{ "width": "60px" }} block type="primary" size='small'><small>Finished</small></Button>
                                    <Button className="mini-btn" block danger type="primary" size='small'><small>Delete</small></Button>
                                    <Button className="mini-btn" block type="primary" size='small'><small>Rename</small></Button>
                                </Space>
                            </div>}><MoreOutlined style={{ "fontSize": "18px", "marginTop": "50%" }} /></Popover>
                        </div>
                    </a>
                </div>
                <div className={(show) ? 'expand' : "contract"} >
                    {(show &&
                        <div>
                            {
                                imprints.map(item => {
                                    return (
                                        <PublisherRow accept={accept} type={type} can_drag={can_drag} className={className}>
                                            <div>
                                              
                                            <ImprintItem data={item} />
                                            </div>
                                        </PublisherRow>
                                    )
                                })
                            }
                        </div>
                    )}
                </div>
            </div>
        )
    }


    const ImprintItem = (props) => {

        const { data = {} } = props;

        const {
            label = "",
            id = "",
            type = "",
            status = "",
            count = null,
            title_count = null
        } = data;

        return (
            <div className='imprint' style={{ "padding": "4px 0px" }}>
                <div className='bcg2' style={{"height" : "1px", "marginTop" : "-4px", "opacity" : "0.5"}}></div>
                <div className='shim' />
                <div style={{ "padding": "4px 10px" }} className='float-flex'>
                    <div style={{ "width": "100%" }}>
                        <div style={{ "margin": "-5px" }}><small><Space size={10}><Badge style={{ "backgroundColor": "#00000088", "boxShadow": "none" }} dot /><em><Html className="pub-text" html={label} /></em></Space></small></div>
                        <div className='float-flex'>
                            <div>&nbsp;</div>
                            <Space>
                                <a><small>{title_count}  {pluralize(title_count, "title", "s")}</small></a>
                            </Space>
                        </div>
                    </div>
                    <a>
                        <div style={{ "width": "20px", "padding": "5px" }}>
                            <Popover overlayStyle={{ "padding": "0px" }} placement="right" trigger="click" content={<div>
                                <Space size={0} direction="vertical">
                                    <Button className="mini-btn" style={{ "width": "60px" }} block type="primary" size='small'><small>Finished</small></Button>
                                    <Button className="mini-btn" block danger type="primary" size='small'><small>Delete</small></Button>
                                    <Button className="mini-btn" block type="primary" size='small'><small>Rename</small></Button>
                                </Space>
                            </div>}><MoreOutlined style={{ "fontSize": "18px", "marginTop": "50%" }} /></Popover>
                        </div>
                    </a>
                </div>
            </div>
        )
    }




    const [loading, setLoading] = useState(true);

    const [filters, setFilters] = useState({
        flag_filters: "",
        publisher_filter: "",
        imprint_filter: "",
        supplier_filter: "",
        isbn_prefix_filter: "",
        sortby: "edited"
    })

    const [fp, setFP] = useState({ rows: [] })

    const toggleFlag = (_flag) => {
        if (_flag === filters.flag_filters) {
            _flag = ""
        }
        setFilters({ ...filters, "flag_filters": _flag })
    }

    const fetchPublisherImprints = () => {
        setLoading(true)
        apiCall("publisher_imprint/getFinalPublisherImprints", filters, (_status, _result) => {
            if (_status) {
                setFP(_result);
                setLoading(false)
            } else {
                message.error(_result.error)
            }
            setLoading(false)
        })

    }

    useEffect(fetchPublisherImprints, [filters]);

    const getClass = (_item, _final = false) => {

        let _c = "";
        const _status = _item?.status || "";

        if(_final){
            if (_status.includes("moved") || _status.includes("finished")) {
                _c = "pub-moved";
            } else {
                _c = "pub-dirty";
            }
            if (_item?.id === selected?.id) {
                _c += " pub-selected";
            }
        } else {
            _c = "pub-raw";

        }

       
        return "pub " + _c;

    }


    const [prefix, setPrefix] = useState("")

    const finalPublisherFilters = (props) => {



        const drawNew = () => {
            return (
                <Overlay width={300}
                    component={
                        <div style={{ "padding": "20px" }}>
                            <strong>Create new Entity</strong>
                            <Divider style={{ "margin": "15px 0px" }} dashed />
                            <Form layout="vertical">
                                <Form.Item label="Name">
                                    <Input placeholder='Name...' />
                                </Form.Item>
                            </Form>
                            <div className='float-flex'>
                                <div>&nbsp;</div>
                                <Space>
                                    <Button>Cancel</Button>
                                    <Button type="primary">Create</Button>
                                </Space>
                            </div>
                        </div>
                    }>
                    <Button style={{ "lineHeight": "10px", "height": "18px", "marginTop": "3px" }} type='primary' size='small'><small>New</small></Button>
                </Overlay>
            )
        }

        return (
            <div>
                <div style={{ "height": "23px" }} className='float-flex'>
                    <div className='c'>Final publishers</div>
                    {drawNew()}
                </div>
                <Divider style={{ "margin": "5px -10px", "position": "absolute" }} />
                <div className='shim' /><div className='shim' />
                <div>
                    <Space size={0}>
                        <Checkbox checked={(filters.flag_filters === "clean")} onChange={(e) => toggleFlag(e.target.value)} value="clean" ><small>Clean</small></Checkbox>
                        <Checkbox checked={(filters.flag_filters === "dirty")} onChange={(e) => toggleFlag(e.target.value)} value="dirty" ><small>Dirty</small></Checkbox>
                        <Checkbox checked={(filters.flag_filters === "finished")} onChange={(e) => toggleFlag(e.target.value)} value="finished" ><small>Finished</small></Checkbox>
                        <Checkbox checked={(filters.flag_filters === "notfinished")} onChange={(e) => toggleFlag(e.target.value)} value="notfinished"><small>Unfinished</small></Checkbox>
                    </Space>
                </div>
                <div className='shim' />
                
                <div><Input.Search enterButton onSearch={(e) => setFilters({ ...filters, "publisher_filter": e })} placeholder={'Publisher...'} allowClear size='small' /></div><div className='shim' />
                <div><Input.Search onSearch={(e) => setFilters({ ...filters, "imprint_filter": e })} placeholder={'Imprint...'} allowClear size='small' /></div><div className='shim' />
                <div><Input.Search className={(dragging === "search") ? "hover-highlight" : ""}  value={prefix} onChange={(e) => setPrefix(e.target.value)} enterButton onSearch={(e) => setFilters({ ...filters, "isbn_prefix_filter": e })} placeholder={'ISBN Prefix...'} allowClear size='small' /></div><div className='shim' />
                <div>
                    <Select placeholder="Pubstock filter..." size='small' style={{ "width": "100%" }}>
                        <Select.Option><small>Option</small></Select.Option>
                    </Select>
                </div>
                <div className='shim' />
                <div className='float-flex'>
                    <div>&nbsp;</div>
                    <div>
                        <Radio.Group onChange={(e) => setFilters({ ...filters, "sortby": e.target.value })} value={filters.sortby} size='small'>
                            <Radio value={"alpha"}><small>Alphabetical</small></Radio>
                            <Radio value={"count"}><small># Titles</small></Radio>
                            <Radio value={"edited"}><small>Date Edited</small></Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className='shim' /><div className='shim' />
                <Spin spinning={loading}>
                    <div className='bc' style={{ "height": "calc(100vh - 488px)", "overflow": "auto", "overflowX": "hidden", "border": "1px solid", "borderRadius": "0px" }}>
                        {fp.rows.map(item => {
                            return (
                                <PublisherRow accept={["publisher", "raw"]} can_drag={false} dragging={dragging} setDragging={setDragging} id={item.id} onDrop={onDrop} >
                                    <PubItem accept={["publisher", "raw"]} can_drag={false} className={getClass(item, true)} callback={(e) => {
                                        setSelected(e);
                                        setImprint({publisher : e, final : true});
                                    }} data={item} />
                                </PublisherRow>
                            )
                        })}
                    </div>
                </Spin>
                <div className='shim' />

            </div>
        )
    }



    const [lpfilters, setLPFilters] = useState({
        publisher_filter: "",
        imprint_filter: "",
        supplier_filter: "",
        isbn_prefix_filter: "",
        sortby: "alpha" //  alpha count edited
    })

    const [lp, setLP] = useState({ rows: [] })
    const [lpLoading, setLPLoading] = useState(false);

    const fetchLinkedImprints = () => {
        if (!selected) { return; }
        setLPLoading(true)

        apiCall("publisher_imprint/getMappedPublisherImprints", { ...lpfilters, "publisher": selected.id }, (_status, _result) => {
            if (_status) {
                setLP(_result);
                setLoading(false)
            } else {
                message.error(_result.error)
            }
            setLPLoading(false)
        })
    }

    useEffect(fetchLinkedImprints, [selected, lpfilters])

    const linkedPublisherFilters = (props) => {

        return (
            <Spin indicator={<></>} spinning={!selected}>
                <div style={{ "height": "auto", "minHeight": "23px" }} className='float-flex'>
                    <div>Raw publishers linked to : {selected?.label}</div>
                </div>
                <Divider style={{ "margin": "5px -10px", "position": "absolute" }} />
                <div className='shim' /><div className='shim' /><div className='shim' /><div className='shim' />
                <div><Input.Search enterButton onSearch={(e) => setLPFilters({ ...lpfilters, "publisher_filter": e })} placeholder={'Publisher...'} allowClear size='small' /></div><div className='shim' />
                <div><Input.Search enterButton onSearch={(e) => setLPFilters({ ...lpfilters, "imprint_filter": e })} placeholder={'Imprint...'} allowClear size='small' /></div><div className='shim' />
                <div>
                    <Select placeholder="Pubstock filter..." size='small' style={{ "width": "100%" }}>
                        <Select.Option><small>Option</small></Select.Option>
                    </Select>
                </div>
                <div className='shim' />
                <div className='shim' />
                <Spin spinning={lpLoading}>
                    <div style={{ "height": "calc(100vh - 414px)", "overflow": "auto", "overflowX": "hidden", "border": "1px solid #eee", "borderRadius": "5px" }}>
                        {lp.rows.map(item => {
                            return (
                                <PublisherRow accept="raw" dragging={dragging} type="publisher" setDragging={setDragging} id={item.id} onDrop={onDrop} >
                                    <PubItem accept="raw" type="publisher" data={item} className={getClass(item)} callback={(e) => {
                                        setImprint({publisher : e, final : false});
                                    }} />
                                </PublisherRow>
                            )
                        })}
                    </div>
                </Spin>
                <div className='shim' />
            </Spin>
        )
    }


    // ALL RAW PUBLISHERS

    const [allFilters, setAllFilters] = useState({
        publisher_filter: "",
        imprint_filter: "",
        supplier_filter: "",
        isbn_prefix_filter: "",
        sortby: "alpha" //  alpha count edited
    })

    const [ap, setAP] = useState({ rows: [] })
    const [apLoading, setAPLoading] = useState(false);

    const fetchAllPublishers = () => {
        if (!selected) { return; }
        setAPLoading(true)

        apiCall("publisher_imprint/getRawPublisherImprints", allFilters, (_status, _result) => {
            if (_status) {
                setAP(_result);
                setLoading(false)
            } else {
                message.error(_result.error)
            }
            setAPLoading(false);
        })
    }

    useEffect(fetchAllPublishers, [allFilters])


    const [prefix2, setPrefix2] = useState("")

    const allPublisherFilters = () => {

        const drawImport = () => {
            return (
                <Overlay width={300}
                    component={
                        <div style={{ "padding": "20px" }}>
                            <strong>Create new Entity</strong>
                            <Divider style={{ "margin": "15px 0px" }} dashed />
                            <Form layout="vertical">
                                <Form.Item label="Name">
                                    <Input placeholder='Name...' />
                                </Form.Item>
                            </Form>
                            <div className='float-flex'>
                                <div>&nbsp;</div>
                                <Space>
                                    <Button>Cancel</Button>
                                    <Button type="primary">Create</Button>
                                </Space>
                            </div>
                        </div>
                    }>
                    <Button style={{ "lineHeight": "10px", "height": "18px", "marginTop": "3px" }} type='primary' size='small'><small>Import</small></Button>
                </Overlay>
            )
        }

        return (
            <Spin indicator={<></>} spinning={!selected}>

                <div style={{ "height": "23px" }} className='float-flex'>
                    <div>All raw publishers / imprints</div>
                    {drawImport()}
                </div>
                <Divider style={{ "margin": "5px -10px", "position": "absolute" }} />
                <div className='shim' /><div className='shim' /><div className='shim' /><div className='shim' />
                <div><Input.Search enterButton onSearch={(e) => setAllFilters({ ...allFilters, "publisher_filter": e })} placeholder={'Publisher...'} allowClear size='small' /></div><div className='shim' />
                <div><Input.Search enterButton onSearch={(e) => setAllFilters({ ...allFilters, "imprint_filter": e })} placeholder={'Imprint...'} allowClear size='small' /></div><div className='shim' />
                <div><Input.Search enterButton className={(dragging === "search") ? "hover-highlight" : ""} value={prefix2} onChange={(e) => setPrefix2(e.target.value)} onSearch={(e) => setAllFilters({ ...allFilters, "isbn_prefix_filter": e })} placeholder={'ISBN Prefix...'} allowClear size='small' /></div><div className='shim' />
                <div>
                    <Select placeholder="Pubstock filter..." size='small' style={{ "width": "100%" }}>
                        <Select.Option><small>Option</small></Select.Option>
                    </Select>
                </div>
                <div className='shim' />
                <div className='float-flex'>
                    <div>&nbsp;</div>
                    <div>
                        <Radio.Group onChange={(e) => setAllFilters({ ...allFilters, "sortby": e.target.value })} value={allFilters.sortby} size='small'>
                            <Radio value={"alpha"}><small>Alphabetical</small></Radio>
                            <Radio value={"count"}><small># Titles</small></Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className='shim' /><div className='shim' />
                <Spin spinning={apLoading}>
                    <div style={{ "height": "calc(100vh - 470px)", "overflow": "auto", "overflowX": "hidden", "border": "1px solid #eee", "borderRadius": "5px" }}>
                        {ap.rows.map(item => {
                            return (
                                <PublisherRow type="raw" dragging={dragging} setDragging={setDragging} id={item.id} onDrop={onDrop} >
                                    <PubItem type="raw" data={item} />
                                </PublisherRow>
                            )
                        })}
                    </div>
                </Spin>
                <div className='shim' />

            </Spin>
        )
    }


    const [imprint, setImprint] = useState({ publisher: {}, final: false });

    const [imp, setImp] = useState({ rows: [] })
    const [impLoading, setImpLoading] = useState(false);

    const fetchPrefixes = () => {

       
        if ((!imprint?.publisher?.imprints) || !selected) { return; }
        let _api = "publisher_imprint/getPrefixesForRawImprint";
        if (imprint?.final) { _api = "publisher_imprint/getPrefixesForFinalImprint" }
        setImpLoading(true);
        apiCall(_api, { "sortby": "alpha", "id": imprint?.publisher?.imprints[0]?.id }, (_status, _result) => {
            if (_status) {
                setImp(_result);
            } else {
                message.error(_result.error)
            }
            setImpLoading(false);
        })
    }

    useEffect(fetchPrefixes, [imprint])






    const publisherPrefixes = () => {

        return (
            <Spin indicator={<></>} spinning={!imprint?.publisher?.id}>
                <div style={{ "minHeight": "23px" }} className='float-flex'>
                    <div>Prefixes for Publisher</div>
                </div>
                <Divider style={{ "margin": "5px -10px", "position": "absolute" }} />
                <div className='shim' /><div className='shim' /><div className='shim' /><div className='shim' />
                <div className='float-flex'>
                    <div>
                        <Radio.Group size='small'>
                            <Radio value={1}><small>Alphabetical</small></Radio>
                            <Radio value={2}><small># Titles</small></Radio>
                        </Radio.Group>
                    </div>
                    <div>&nbsp;</div>
                </div>
                <div className='shim' />
                <Spin spinning={impLoading}>
                    <div style={{ "height": "calc(100vh - 350px)", "overflow": "auto", "overflowX": "hidden", "border": "1px solid #eee", "borderRadius": "5px" }}>
                        {imp.rows.map(item => {
                            return (
                                <PublisherRow type="search" dragging={dragging} setDragging={setDragging} item={item} id={item.id} onDrop={onDrop} >
                                    <PubItem data={item} />
                                </PublisherRow>
                            )
                        })}
                    </div>
                </Spin>
                <div className='shim' />
            </Spin>
        )
    }




    return (
        <>
            {dragging}
            <Layout className="layout" >
                <LayoutHeader
                    title={<div className='float-flex'><div>Publishers & Imprints</div>
                        <Space>
                            <div className='pub-dirty bc2' style={{"boxShadow" : "inset 0 0 2px #00000022", "borderRadius" : "10px", "padding" : "0px 15px"}}><small style={{"fontSize" : "12px", "lineHeight" : "20px"}}>Dirty</small></div>
                            <div className='pub-moved bc2' style={{"boxShadow" : "inset 0 0 2px #00000022", "borderRadius" : "10px", "padding" : "0px 15px"}}><small style={{"fontSize" : "12px", "lineHeight" : "20px"}}>Cleaned</small></div>
                            <div className='pub-raw bc2' style={{"boxShadow" : "inset 0 0 2px #00000022", "borderRadius" : "10px", "padding" : "0px 15px"}}><small style={{"fontSize" : "12px", "lineHeight" : "20px"}}>Raw Publisher</small></div>
                            <div className='pub-raw' style={{"borderRadius" : "10px"}}><div className='imprint bc2' style={{"boxShadow" : "inset 0 0 2px #00000022", "borderRadius" : "10px", "padding" : "0px 15px"}}><small style={{"fontSize" : "12px", "lineHeight" : "20px"}}>Raw Imprint</small></div></div>
                            
                        </Space>
                    </div>}

                // description="Use this page to manage publishers and imprints."
                />
               
                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "10px" }}>
                        <DndProvider backend={HTML5Backend}>
                            <Row gutter={10}>
                                <Col span={6}>
                                    <PublisherCard className='bcg3' drop_type="search" id="final" onDrop={onDrop} >
                                        {finalPublisherFilters()}
                                    </PublisherCard>
                                </Col>
                                <Col span={6}>
                                    <PublisherCard className='bcg3' drop_type="BOX" id="raw" onDrop={onDrop} >
                                        {linkedPublisherFilters()}
                                    </PublisherCard>
                                </Col>
                                <Col span={6}>
                                    <PublisherCard drop_type="search" id="all" onDrop={onDrop} >
                                        {allPublisherFilters()}
                                    </PublisherCard>
                                </Col>
                                <Col span={6}>
                                    <PublisherCard drop_type="SEARCH" id="prefix" onDrop={onDrop} >
                                        {publisherPrefixes()}
                                    </PublisherCard>
                                </Col>
                            </Row>
                        </DndProvider>

                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}