import { Col, Divider, Modal, Row, Tooltip } from "antd";
import moment from "moment-timezone";
import React, { useState } from "react";
import { QuestionCircleTwoTone } from '@ant-design/icons';
import conditional from "../utils/conditional";
import { useSession } from "../utils/Session";
import DrawSuggestedCarts from './carts/DrawSuggestedCarts';
import Condition from "./Condition";
import PeerSales from "./PeerSales";
import LifetimeSales from "./LifetimeSales";

export default function Conditions(props) {
    const {
        isbn = "",
        data = {},
        sideColumn = true,
        storeData = false,
        openPeerDataTab = false,
        peerDataOpen = false,
        setPeerDataOpen = () => { }
    } = props

    const [showActivity, setShowActivity] = useState("");
    const [session, setSession] = useSession();
    const [openState, setOpenState] = useState("")
    const [conditions, setConditions] = useState((props.conditions) ? props.conditions : []);

    function toggleActivity(_view, _lbl = "") {


        setPeerDataOpen(true);

        return;
        let reopen = (_lbl !== openState);
        setOpenState(_lbl)
        if (_lbl === "peer" && openPeerDataTab) {
            setShowActivity("peer");
        }
        setShowActivity(((_view) && _view === showActivity) ? "" : _view)
        if (reopen) {
            setTimeout(() => {
                setShowActivity(_view)
            }, 1)
        }
    }

    function checkIsMultiStore(name) {
        let isMultiStore = false
        session.stores_working_with.forEach((store) => {
            if (store.stores.length > 1 && store.name === name) {
                isMultiStore = true
            }
        })
        return isMultiStore
    }

    const showPeerTooltip = () => {

        Modal.info({
            icon : null, 
            width : 550,
            okText : "Close",
            title : "Peer OH/OO Information",
            content: <>
                <Divider dashed style={{"margin" : "15px 0px"}} />
                <div className='bc'>
                <strong>Sales data</strong> is collected weekly from indie bookstores across the country. These are the numbers you see under each month here, and represent actual raw sales data from stores, and are not weighted in any way. (FYI: The peer data that bookstores see is weighted. And our sales / forthcoming ranking engine uses these numbers in a weighted formula to better represent relative performance.)
                    <br />
                    <br />
                    <strong>OH</strong> = Total number currently on hand; The superscript is  the number of stores with it on hand, and the larger number is the total amount on hand for all those stores combined.
                    <br />
                    <br />
                    <strong>OO</strong> = Total number currently on order; The superscript is  the number of stores with it on order, and the larger number is the total amount on order for all those stores combined.
                    
                </div>
            </>
        })

    }

    function drawValues(_type = "", _cart_qty = null) {
        let { total_stores = 0, total_stores_region } = data.sales_summary;
        let {
            months = ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
            week = 0,
            onhandlocs = 0,
            onorderlocs = 0,
            onhand = 0,
            onorder = 0,
        } = data.sales_summary;
        let _class = "rowRawData";
        return (

            <Row onClick={() => toggleActivity("peer")} className={_class + " bc rowHover"} style={{ "fontSize": "11px", "lineHeight": "22px", "fontWeight": "normal", "textAlign": "right", "borderBottom": "0px solid" }}>
                <conditional.true value={(_type === "Peer")}>
                    <Col span="4" className="bc" style={{ "borderRight": "1px solid", "borderTop": "none", "textAlign": "center", "paddingLeft": "2px", "fontWeight": "normal", "fontSize": "10px" }}>
                        <Tooltip title={<small>{total_stores} active stores have sold this item in the last 13 months, or currently have it O/H or O/O</small>}>
                            <span style={{ "float": "right" }}>{total_stores}{total_stores_region} Peers&nbsp;</span>
                        </Tooltip>
                    </Col>
                </conditional.true>
                {months.map((item, _index) => {
                    return (
                        <Col
                            span="1"
                            key={_index}
                            style={{ "textAlign": "right", "paddingRight": "2px" }}
                        >{(item) ? item : ""}
                        </Col>
                    );
                })}
                <Tooltip title={<small>Copies sold in the last 7 days, ending yesterday.</small>}>
                    <Col span="1" className="bc" style={{ "borderLeft": "1px solid", "paddingRight": "2px", "borderRight": "1px solid #000" }}>
                        <em>{(week) ? week : ""}</em>
                    </Col>
                </Tooltip>
                <conditional.true value={(_type === "Peer")}>
                    <Col span="2" className="bc" style={{ "borderLeft": "1px solid", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px", "fontSize": "10px" }}>
                        <div style={{ "float": "right", "width": "33px", "overflow": "hidden", "fontSize": "9px" }}>
                            <nobr>
                                {(onhand) ? onhand : ""}<sup>{(onhandlocs) ? onhandlocs : ""}</sup>
                            </nobr>
                        </div>
                    </Col>
                    <Col span="2" className="bc" style={{ "borderLeft": "1px solid", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px", "fontSize": "10px" }}>
                        <div style={{ "float": "right", "width": "34px", "overflow": "hidden", "fontSize": "9px" }}>
                            <nobr>
                                {(onorder) ? onorder : ""}<sup>{(onorderlocs) ? onorderlocs : ""}</sup>
                            </nobr>
                        </div>
                    </Col>
                </conditional.true>
                <conditional.true value={(_cart_qty !== null)}>
                    <div style={{ "position": "absolute", "fontSize": "10px", "right": "-11px", "width": "20px", "height": "23px", "backgroundColor": "#abdeff", "padding": "0px 2px", "color": "#000" }} >
                        {(_cart_qty) ? _cart_qty : <>&nbsp;</>}
                    </div>
                </conditional.true>
                <Tooltip title={<div style={{ "lineHeight": "14px" }}><small>Actual monthly sales at all stores <br />Click to view breakdown</small></div>}>
                    <div style={{ "height": "22px", "position": "absolute", "width": "340px", "left": "112px", "zIndex": "0" }}></div>
                </Tooltip>
            </Row>

        )
    }

    const drawHeadersRow = () => {
        let _months = ["Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"]
        _months = _months.map((item, index) => {
            return moment().startOf("month").add(-(_months.length - (index + 1)), "month").format("MMM");
        });
        return (
            <Row className="bc" style={{ "fontSize": "11px", "color": "#aaa", "textAlign": "right", "borderBottom": "0px solid", "lineHeight": "22px" }}>
                <Col span="2" className="bc" >&nbsp;</Col>
                <Col span="2" className="bc c" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px" }}>Peers <QuestionCircleTwoTone onClick={(e) => showPeerTooltip()} /></Col>
                {_months.map((item, _index) => {
                    return (
                        <Col
                            onClick={() => toggleActivity("sales")}
                            key={_index}
                            style={{ "cursor": "pointer" }}
                            span="1"
                        >
                            <span className="c">{item}</span>
                        </Col>
                    )
                })}
                <Col
                    span="1"
                    className="bc"
                    style={{ "borderLeft": "1px solid", "borderRight": "1px solid", "paddingRight": "2px" }}>
                    <span className="c">Wk</span>
                </Col>
                <Col
                    span="2"
                    className="bc"
                    style={{ "borderLeft": "1px solid", "paddingRight": "2px" }}>
                    <span className="c">OH</span>
                </Col>
                <Col
                    span="2"
                    className="bc"
                    style={{ "borderLeft": "1px solid", "paddingRight": "2px" }}>
                    <span className="c">OO</span>
                </Col>
                <Col span="1"></Col>
            </Row>
        )
    }

    function checkIfStoreHasSelectedSCs(store) {
        let has_selected_SCs = false
        session.suggested_carts_working_with.forEach((cart) => {
            if (cart.store_info.san === store.san) {
                has_selected_SCs = true;
            }
        })
        return has_selected_SCs;
    }

    return (
        <>
            {!data.from_series_count && data.can_see_sales && !storeData && <div className="bc-light bc" style={{ "marginTop": "10px", "marginBottom": "5px", border: "1px solid", borderLeft: "6px solid" }}>
                {drawHeadersRow()}
                {drawValues("Peer")}
            </div>}
            <conditional.true value={sideColumn}>
                <conditional.true value={(showActivity === "peer")}>
                    <div style={{ "marginTop": "-5px" }}>

                        <LifetimeSales can_see_sales={data.can_see_sales} isbn={isbn} />

                        {/* For the full, expandable peer data breakdown, please click the 'peer data' tab down below. */}

                    </div>
                </conditional.true>
            </conditional.true>
            <conditional.true value={!sideColumn}>
                <conditional.true value={(showActivity === "peer")}>
                    <div style={{ "marginTop": "-5px" }}>
                        <PeerSales
                            sideColumn={sideColumn}
                            total_stores={data.sales_summary.total_stores}
                            setShowActivity={setShowActivity}
                            isbn={isbn} />
                    </div>
                </conditional.true>
                {conditions.map((item, _index) => {
                    return (
                        <div style={{ "width": "62.2vw" }} key={_index}>
                            <Condition
                                sideColumn={sideColumn}
                                suggested_bm_data={data.suggested_bm_data}
                                isbn={isbn}
                                data={item} />
                            <div className="shim" /><div className="shim" />
                        </div>
                    )
                })}
                <conditional.true value={session.suggested_carts_working_with.length > 0 && session.stores_working_with.length > 0}>
                    {session.stores_working_with.map((store, index) => {
                        if (checkIfStoreHasSelectedSCs(store)) {
                            return (
                                <div className="bc-light bc" style={{ "marginTop": "10px", border: "1px solid", borderLeft: "6px solid" }}>
                                    <div>
                                        <DrawSuggestedCarts
                                            isbn={isbn}
                                            storeName={store.name}
                                            isMultiStore={checkIsMultiStore(store.name)}
                                            stores={store.stores}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    }
                    )}
                </conditional.true>
            </conditional.true>
        </>
    )
}