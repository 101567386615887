import { CheckCircleTwoTone, CloseCircleTwoTone, DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Input, message, Radio, Select, Space, Spin, Table, Typography } from "antd"
import { useEffect, useState } from "react"

import nocover from "../../../media/no-cover.png";
import conditional from "../../../tools/conditional"
import { apiCall } from "../../../utils/Api"
import { disabledColor } from '../../../utils/Utils';
import moment from 'moment-timezone';

export default function MultiSelectAddRemoveList(props) {

    const [catalogueList, setCatalogueList] = useState([]);
    const [chosenList, setChosenList] = useState(false);
    const [addHighlights, setAddHighlights] = useState([]);
    const [selectedTitles, setSelectedTitles] = useState([]);
    const [removeShortList, setRemoveShortList] = useState([]);
    const [selectedCat, setSelectedCat] = useState("");
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(false);

    const {
        isbns,
        catalogueID = "",
        action = "",
        titles = [],
        close = () => { },
        available_corps = [],
    } = props

    const count = isbns.length
    const add = action === "add" ? true : false;

    const [affectedCount, setAffectedCount] = useState(0);

    function listAction() {
        let args = {
            list_id: chosenList,
            isbns_json: add ? JSON.stringify(addHighlights) : JSON.stringify(removeShortList)
        }
        let call = "titlelist/" + action;
        apiCall(call, args, (_status, _result) => {
            if (_status) {
                message.success("Catalogue saved successfully.");
            } else {
                message.error("Catalogue did not save successfully.");
            }
            fillCatalogueList()
            close()
        })
    }

    const noCover = (e) => {
        e.target.src = nocover;
        e.target.onerror = null;
    }

    function fillCatalogueList() {
        let args = {
            l: 10000
        }
        if (available_corps.some((corp) => corp.name === "My Personal Account")) {
            let temp_corp = available_corps.find(corp => corp.name === "My Personal Account")
            // args.q = temp_corp.id
        }
        setLoading(true);
        apiCall("titlelist/getMyCatalogues", args, (_status, _result) => {
            if (_status) {
                setCatalogueList(_result.rows);
                if (catalogueID && !add) {
                    let _find = _result.rows.find(item => item.list_id === catalogueID);
                    if (_find) {
                        setSelectedCat(catalogueID);
                        setChosenList(catalogueID);
                    }
                }
            }
            setLoading(false);
        })
    }

    function fillAddHighlights() {
        setAddHighlights(isbns.map((isbn, index) => {
            return {
                "isbn": isbn,
                "highlight": false
            }
        }))
    }

    function changeHighlights(changed_isbn, checked) {
        let tempAddHighlights = [...addHighlights]
        tempAddHighlights = tempAddHighlights.map((isbn, index) => {
            if (checked !== isbn.highlight && changed_isbn === isbn.isbn) {
                isbn.highlight = checked
            }
            return isbn;
        })
        setAddHighlights(tempAddHighlights)
    }

    function selectedTitlesForTableData() {
        let titles_for_table = isbns.map((isbn, index) => {
            if (titles.find((title) => title.isbn === isbn)) {
                return titles.find((title) => title.isbn === isbn)
            }
        })
        setSelectedTitles(titles_for_table);
    }

    useEffect(fillCatalogueList, []);
    useEffect(fillAddHighlights, []);
    useEffect(selectedTitlesForTableData, [], chosenList);


    function checkForISBNExistanceInACatalogue() {
        let temp_count = 0;
        selectedTitles.forEach((title_row) => {
            if (title_row.my_catalogues.find((cata) => cata === chosenList)) {
                temp_count++
            }
        })
        setAffectedCount(temp_count)
    }
    useEffect(checkForISBNExistanceInACatalogue, [chosenList]);

    function paragraphText() {
        if (add) {
            if (isbns.length > 1) {
                return "You are about to add " + isbns.length + " titles to the catalogue chosen below."
            } else if (isbns.length === 1) {
                return "You are about to add " + isbns.length + " title to the catalogue chosen below."
            }
        }
        else if (action === "remove") {
            if (affectedCount > 1) {
                return "You are about to remove " + affectedCount + " titles from the catalogue chosen below."
            } else if (affectedCount === 1) {
                return "You are about to remove " + affectedCount + " title from the catalogue chosen below."
            } else if (affectedCount === 0) {
                return "You have not chosen any applicable titles to remove from a catalogue"
            }
        }
        return "You have not chosen any titles to work with.";
    }



    const columns = [
        {
            title: <small>Cover</small>, width: "30px", dataIndex: 'eisbn', key: 'eisbn', render: (e, f) => {
                return <>
                    <img className="shadow-large" onError={(e) => noCover(e)} style={{ "width": "30px", "marginRight": "10px" }} src={"https://cdn1.bookmanager.com/i/b?b=" + e} alt="" />
                </>;
            }
        },
        {
            title: <small>ISBN</small>, width: '70px', dataIndex: 'isbn', key: 'isbn'
        },
        {
            title: <small>Title</small>, width: "150px", dataIndex: 'title', key: 'title'
        },
    ];

    if (add) {
        columns.unshift({
            title: <small>Highlight</small>, width: "25px", render: (e, f) => {
                return (<Checkbox onClick={(e) => changeHighlights(f.isbn, e.target.checked)} />)
            }
        })
    } else {
        columns.unshift({
            title: <small>Affected</small>, width: "25px", render: (e, f) => {
                if (f.my_catalogues.find((cata) => cata === chosenList)) {
                    let temp_cats = removeShortList;
                    temp_cats.push(f.isbn)
                    setRemoveShortList(temp_cats)
                    return (<CheckCircleTwoTone style={{ "fontSize": "20px" }} twoToneColor="#07fc03" />)
                }
                return (<CloseCircleTwoTone style={{ "fontSize": "20px" }} twoToneColor="#f50f22" />)
            }
        })
    }

    function handleListChange(new_list) {
        setSelectedCat(new_list);
        setChosenList(new_list)
    }

  


    const my_columns = [
    
        {
            title: <small></small>, width : 40, dataIndex: 'select', key: 'select', render: (e, f) => {
                return (<div style={{"textAlign" : "center"}}><Checkbox checked={selectedCat === f.list_id} onClick={() => {
                    if(selectedCat === f.list_id){
                        handleListChange("")
                    } else {
                        handleListChange(f.list_id)
                    }
                    
                }} /></div>)
            }
        },

        {
            title: <small>Catalogue name</small>, dataIndex: 'name', key: 'name', render: (e, f) => {
                return (<div>{e}</div>)
            }
        },
        {
            title: <small>Created by:</small>, dataIndex: 'created_by', key: 'created_by', render: (e, f) => {
                if (!e) { return (<></>) }
                return (<div>{e}</div>)
            }
        },
        {
            title: <small><nobr>Last edited by:</nobr></small>, dataIndex: 'last_edited_by', key: 'last_edited_by', render: (e, f) => {
                if (!e) { return (<></>) }
                return (<div>{e}</div>)
            }
        },

        {
            title: <small>Titles</small>, width: '55px', dataIndex: 'isbns_count', key: 'isbns_count'
        },
        {
            title: <small>Last Updated</small>, width : "90px", dataIndex: 'date_updated', key: 'date_updated', render: (e, f) => {
                return (
                    <>
                        <div><nobr>{moment(e * 1000).format("MMMDD-YY")}</nobr></div><div style={{ "color": disabledColor }}>
                        </div>
                    </>
                )
            }
        },
        {
            title: <small>Category</small>, dataIndex: 'category', key: 'category', render: (e, f) => {
                return <><div style={{ "color": disabledColor }}><em>{e}</em></div><div style={{ "fontWeight": "500", "maxWidth": "100px", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis" }}>{f.owner_name}</div></>
            }
        },
        {
            title: <small>Season</small>, width : "100px", dataIndex: 'effective_string', key: 'effective_string'
        },
    ];



    return (
        <>
            <div style={{ "padding": "0px" }}>
                <Spin spinning={loading}>
                    <Typography.Title level={5}>{add ? "Add ISBNs to a Catalogue" : "Remove ISBNs from a Catalogue"}</Typography.Title>
                    <Divider style={{ "margin": "15px 0px" }} />
                    <conditional.true value={chosenList !== false}>
                        <Typography.Paragraph>
                            {paragraphText()}
                        </Typography.Paragraph>
                    </conditional.true>


                    <Input.Search onChange={(e) => setSearch(e.target.value.toLowerCase())} allowClear placeholder='Search catalogue name...' />
                    <div className='shim' />
                    <Table
                        size="small"
                        bordered
                        columns={my_columns}
                        loading={loading}
                        scroll={{y : 200}}
                        dataSource={catalogueList.filter(item => item.name.toLowerCase().includes(search))}
                        pagination={false}
                        
                    />
                    <Divider dashed />
                   
                    <Table
                        size="small"
                        bordered
                        scroll={{y : 200}}
                        pagination={false}
                        columns={columns}
                        dataSource={selectedTitles}
                    />
                    <Divider style={{ "margin": "15px 0px" }} />
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={(e) => close()}>Cancel</Button>
                            <Button
                                type="primary"
                                disabled={action === "remove" && affectedCount === 0}
                                onClick={listAction}
                            >Save {add ? "Additions" : "Removals"}
                            </Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Spin>
            </div>
        </>
    )
} 