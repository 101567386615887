import { Button, Result } from "antd";
import { ReloadOutlined } from '@ant-design/icons';
import React, { useState } from "react";

export default function ButtonWrap(props) {

    const { title = "" } = props;
    const [visible, setVisible] = useState(false);

    if (visible) {
        return (props.children);
    }
    return (
        <div className="c" style={{ "backgroundColor": "#88888811", "textAlign": "center", "padding": "20px" }}>
            <Button onClick={() => setVisible(true)} style={{ "width": "230px" }}>{title}</Button>
        </div>
    )
}