import React, { useEffect, useState } from "react";
import { apiCall } from "../utils/Api";
import { Col, message, Row, Tooltip } from "antd";

export default function LifetimeSales(props) {

    const { isbn = "", can_see_sales = false } = props;
    const [results, setResults] = useState({sales : []});

    const getLifetimeSales = () => {
        apiCall("title/getLifetimeSales", { isbn: isbn }, (_status, _result) => {
            if (_status) {
                setResults(_result);
            } else {
                message.error(_result.error);
            }
        })
    }

    useEffect(getLifetimeSales, [])

    const drawHeadersRow = () => {
        let _months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        return (
            <Row className="bc" style={{ "fontSize": "12px", "color": "#aaa", "textAlign": "right", "borderBottom": "0px solid", "lineHeight": "22px" }}>
                <Col flex={"70px"} className="bc c" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px" }}>Year</Col>
                {_months.map((item, _index) => {
                    return (
                        <Col
                            key={_index}
                            style={{ "cursor": "pointer" }}
                            flex={"85px"}
                        >
                            <span className="c">{item}</span>
                        </Col>
                    )
                })}
                <Col flex={"10px"} className="bc c" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px" }}></Col>
                <Col flex={"70px"} className="bc c" style={{ "textAlign": "right", "paddingRight": "2px" }}>Ttl</Col>
            </Row>
        )
    }


    function drawValues(_data = {}, _type = "", _cart_qty = null) {

        let {
            isbn = "",
            year = "",
            total_sold = 0,
            total_locs = 0,
            sold = ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
            locs = ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
        } = _data;
        let _class = "rowRawData";
        return (
            <Row className={_class + " bc"} style={{ "fontSize": "14px", "lineHeight": "22px", "fontWeight": "normal", "textAlign": "right", "borderBottom": "0px solid" }}>

                <Col flex={"70px"} className="bc c" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px" }}>{year}</Col>
                {sold.map((item, _index) => {
                    return (
                        <Col
                            flex={"85px"}
                            key={_index}
                            style={{ "textAlign": "right", "paddingRight": "2px" }}
                        ><>{(item) ? item : ""}</>
                        </Col>
                    );
                })}
                <Col flex={"10px"} className="bc c" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px" }}></Col>
                <Col flex={"70px"} className="bc c" style={{ "textAlign": "right", "paddingRight": "2px" }}><span className="rowRawData">{total_sold}<sup>{total_locs}</sup></span></Col>
            </Row>
        )
    }

    if(!can_see_sales){
        return(<></>)
    }
    
  

    return (
        <div>
            <div className="bc" style={{ "marginLeft": "0px", "border": "1px solid", "borderLeft": "6px solid" }}>
            {drawHeadersRow()} {results.sales.reverse().map(item => drawValues(item))}
            </div>
        </div>
    )
}