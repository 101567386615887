import { Button, Checkbox, Input, message, Modal, Radio, Select, Space, Table, Tabs, Tooltip, Typography } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import ListCreateEdit from "../../lists/ListCreateEdit"
import MultiSelectAddRemoveList from "./MultiSelectAddRemoveList";
import MultiSelectExcel from "./MultiSelectExcel";
import MultiSelectHighlightToggle from "./MultiSelectHighlightToggle";
import MultiSelectPDF from "./MultiSelectPDF";
import { useSession } from "../../../utils/Session";

export default function BrowseMultiSelect(props) {

    const [session, setSession] = useSession();
    const {
        close = () => { },
        catalogueID = "",
        available_corps = [],
        titles = [],
        suppliers = "",
        agencies = "",
        categories = "",
        setResults = () => { },
        results = "",
        effectiveDates = "",
        sel = [],
        setSel = () => {}
    } = props;

    const [position, setPosition] = useState("add");
    //states: add, remove, create_catalogue, highlight, unhighlight

    function getFullISBNData(list_edit_values) {
      
        if (list_edit_values) {
            return {
                "isbns_full_data": sel,
                "selectedRowKeys": sel.map((item) => {
                    return {
                        "isbn": item.isbn,
                        "highlight": false
                    }
                })
            }
        }
        return { "isbns_full_data": sel, "selectedRowKeys": sel.map(item => item.isbn) };
    }

    function changeTabs(position) {
        setPosition(position);
    }

    return (
        <div style={{ "padding": "20px" }}>
            <Tabs
                className="itemTabs"
                onChange={(e) => changeTabs(e)}
                defaultActiveKey="0"
                activeKey={position}
                type="card"
            >
                <Tabs.TabPane tab="Add to Catalogue" key="add">
                    <MultiSelectAddRemoveList
                        isbns={sel.map(item => item.isbn)}
                        available_corps={available_corps}
                        action="add"
                        titles={titles}
                        close={close}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Remove from Catalogue" key="remove">
                    <MultiSelectAddRemoveList
                        available_corps={available_corps}
                        catalogueID={catalogueID}
                        isbns={sel.map(item => item.isbn)}
                        action="remove"
                        titles={titles}
                        close={close}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Create Catalogue with Titles" key="create_catalogue">
                    <ListCreateEdit
                        padding={0}
                        create={true}
                        suppliers={suppliers}
                        agencies={agencies}
                        categories={categories}
                        effectiveDates={effectiveDates}
                        results={results}
                        setResults={setResults}
                        close={close}
                        hideimport
                        data={getFullISBNData(true)}
                    />
                </Tabs.TabPane>
                {((catalogueID) &&
                    <Tabs.TabPane tab="Highlight" key="highlight">
                        <MultiSelectHighlightToggle
                            highlight={true}
                            results={results}
                            setResults={setResults}
                            catalogueID={catalogueID}
                            close={close}
                            data={getFullISBNData(false)} />
                    </Tabs.TabPane>
                )}
                {((catalogueID) &&
                    <Tabs.TabPane tab="Unhighlight" key="unhighlight">
                        <MultiSelectHighlightToggle
                            highlight={false}
                            results={results}
                            setResults={setResults}
                            close={close}
                            catalogueID={catalogueID}
                            data={getFullISBNData(false)} />
                    </Tabs.TabPane>
                )}

                {((!session?.subscriptions.salesisbn && !session?.subscriptions.catalogue) &&
                    <Tabs.TabPane tab="PDF" key="pdf">
                        <MultiSelectPDF
                            isbns={sel.map(item => item.isbn)}
                            setSel={setSel}
                            close={close}
                        />
                    </Tabs.TabPane>
                )}
                {((!session?.subscriptions.salesisbn && !session?.subscriptions.catalogue) &&
                    <Tabs.TabPane tab="Excel" key="excel">
                        <MultiSelectExcel
                            isbns={sel.map(item => item.isbn)}
                            setSel={setSel}
                            close={close}
                        />
                    </Tabs.TabPane>
                )}

            </Tabs>
        </div>
    )
}